
import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import { WorkerDetail } from '@/types/worker'
import { Page } from '@/types/common'
import { KeepAlive } from '@/utils/decorators'

@Component({
  components: { ImportData }
})
@KeepAlive
export default class List extends Vue {
  private searchInfo: { projectId: string; workerName: string } = {
    projectId: '',
    workerName: ''
  }

  private itemList = [
    {
      label: '工人姓名',
      prop: 'workerName'
    },
    {
      label: '身份证号',
      prop: 'workerCard'
    },
    {
      label: '所属项目',
      prop: 'projectName'
    },
    {
      label: '电话号码',
      prop: 'workerMobile'
    },
    {
      label: '年龄',
      prop: 'workerAge'
    },
    {
      label: '性别',
      prop: 'workerSexName'
    },
    {
      label: '家庭住址',
      prop: 'homeAddress'
    },
    {
      label: '紧急联系人',
      prop: 'emergencyPerson'
    },
    {
      label: '联系号码',
      prop: 'emergencyPersonMobile'
    }
  ]

  private tableData: WorkerDetail[] = []
  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private isShowImport = false
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getList()
  }

  // 搜索
  onSearch () {
    this.page = 1
    this.getList()
  }

  // 获取列表数据
  getList () {
    this.loading = true
    this.$axios.get<Page<WorkerDetail>>(this.$apis.worker.selectYhWorkerByPage,
      {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.loading = false
    })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }

  // 新增工人
  addWorker () {
    this.$router.push({ name: 'projectWorkerAdd' })
  }

  // 删除工人
  deleteConfirm (row: WorkerDetail) {
    this.$confirm(`是否删除工人【${row.workerName}】?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.worker.deleteYhWorker,
        {
          workerId: row.workerId
        }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }
}
